<template>
  <div class="page"></div>
</template>

<script lang="ts" setup>
const router = useRouter();

onServerPrefetch(async () => {
  if (import.meta.server) {
    await router.push({ name: 'public-home' });
  }
});
</script>
<style lang="sass" scoped>
.page
  min-height: 100vh
  padding: 0 !important
  min-width: 100vw !important
  .bg
    background: url('/bg.svg')
    background-repeat: repeat
    position: absolute
    width: 300%
    height: 100%
    opacity: 0.09
    left: -100%
    top: 0
</style>
